<template>
  <div>
    <section v-if="platform == 1">
      <section
        v-for="item in list"
        :key="item.username || item.id"
        style="margin-bottom: 20px"
      >
        <section class="flex_content">
          <el-avatar
            :src="item.avatarThumbKey"
            style="width: 50px; height: 50px"
          >
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-avatar>
          <div class="info_content">
            <h1>
              <span>{{ item.name || item.nickname }}</span>
              <i
                v-if="item.verification_status == 1"
                class="icon-renzheng iconfont"
                v-tooltip="
                  `已经在${platform == 1 ? 'Facebook' : 'Tiktok'}进行过认证`
                "
              ></i>
              <country-flag
                v-if="item.country_code"
                :country="item.country_code"
                size="normal"
                v-tooltip="`达人所属国家：${item.country}`"
              />
              <!-- <i v-if="item.sex" class="icon-nan iconfont"></i>
          <i class="icon-nv iconfont"></i> -->
              <i
                v-if="item.page_url"
                class="el-icon el-icon-link"
                v-tooltip="`点击跳转到主页地址`"
                @click="openUrl(item.page_url)"
              ></i>
              <i
                v-if="item.emails"
                class="el-icon el-icon-message"
                @click="common._copy(item.emails)"
                v-tooltip="`主页关联的邮箱账号：${item.emails}，点击可复制`"
              ></i>
              <i
                v-if="item.bioLink"
                class="el-icon el-icon-monitor"
                v-tooltip="`点击后跳转到达人的官网`"
                @click="openUrl(item.bioLink)"
              ></i>
              <!-- <i
            class="iconfont icon-instagram"
            v-tooltip="`拥有相同的instagram账号`"
          ></i> -->
            </h1>
            <p v-if="platform == 1">@{{ item.username }}</p>
            <p v-if="platform == 2">ID: {{ item.uniqueId }}</p>
            <p v-if="item.about">
              简介：
              <el-tooltip
                class="item"
                effect="dark"
                :content="item.about"
                placement="top"
              >
                <span>{{ item.about }}</span>
              </el-tooltip>
            </p>
          </div>
          <div class="numbers">
            <el-row :gutter="18">
              <el-col :span="8">
                <p>{{ item.overall_star_rating || 0 }}</p>
                <p>主页评分</p>
              </el-col>
              <el-col :span="8">
                <p v-if="item.followers > 0">
                  <span v-tooltip="`${item.followers}`">{{
                    item.followers2 || 0
                  }}</span>
                </p>
                <p v-else><span>0</span></p>
                <p>粉丝数</p>
              </el-col>
              <el-col :span="8">
                <p v-if="item.publish_count > 0">
                  <span v-tooltip="`${item.publish_count}`">{{
                    item.publish_count2 || 0
                  }}</span>
                </p>
                <p v-else><span>0</span></p>
                <p>帖子数量</p>
              </el-col>
            </el-row>
          </div>
        </section>
        <el-divider></el-divider>
      </section>
    </section>
    <section v-else-if="platform == 3">
      <section
        v-for="item in list"
        :key="item.title"
        style="margin-bottom: 20px"
      >
        <section class="flex_content">
          <el-avatar
            :src="item.avatarThumbKey"
            style="width: 50px; height: 50px"
          >
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-avatar>
          <div class="info_content">
            <h1>
              <span>{{ item.title }}</span>
              <i
                v-if="item.verified == 1"
                class="icon-renzheng iconfont"
                v-tooltip="`已认证`"
              ></i>
              <country-flag
                v-if="item.country_code"
                :country="item.country_code"
                size="normal"
                v-tooltip="`达人所属国家：${item.country}`"
              />
              <i
                v-if="item.page_url"
                class="el-icon el-icon-link"
                v-tooltip="`点击跳转到主页地址`"
                @click="openUrl(item.page_url)"
              ></i>
              <i
                v-if="item.emails"
                class="el-icon el-icon-message"
                @click="common._copy(item.emails)"
                v-tooltip="`主页关联的邮箱账号：${item.emails}，点击可复制`"
              ></i>
              <el-tooltip
                placement="top"
                v-if="item.links && item.links.length > 0"
              >
                <div slot="content">
                  <p
                    v-for="i in item.links"
                    :key="i.name"
                    style="line-height: 24px; text-align: center"
                  >
                    <el-link type="primary" :href="i.url" target="_blank">{{
                      i.name
                    }}</el-link>
                  </p>
                </div>
                <i class="el-icon el-icon-connection"></i>
              </el-tooltip>
            </h1>
            <p>@{{ item.id }}</p>
            <p v-if="item.about">
              简介：
              <el-tooltip
                class="item"
                effect="dark"
                :content="item.about"
                placement="top"
              >
                <span>{{ item.about }}</span>
              </el-tooltip>
            </p>
          </div>
          <div class="numbers">
            <el-row :gutter="18">
              <el-col :span="8">
                <p v-if="item.views > 0">
                  <span v-tooltip="`${item.views}`">{{
                    item.views2 || 0
                  }}</span>
                </p>
                <p v-else><span>0</span></p>
                <p>浏览量</p>
              </el-col>
              <el-col :span="8">
                <p v-if="item.followers > 0">
                  <span v-tooltip="`${item.followers}`">{{
                    item.followers2 || 0
                  }}</span>
                </p>
                <p v-else><span>0</span></p>
                <p>粉丝数</p>
              </el-col>
              <el-col :span="8">
                <p v-if="item.publish_count > 0">
                  <span v-tooltip="`${item.publish_count}`">{{
                    item.publish_count2 || 0
                  }}</span>
                </p>
                <p v-else><span>0</span></p>
                <p>视频数</p>
              </el-col>
            </el-row>
          </div>
        </section>
        <el-divider></el-divider>
      </section>
    </section>
    <section v-else-if="platform == 4">
      <section
        v-for="item in list"
        :key="item.nickname"
        style="margin-bottom: 20px"
      >
        <section class="flex_content">
          <el-avatar
            :src="item.avatarThumbKey"
            style="width: 50px; height: 50px"
          >
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-avatar>
          <div class="info_content">
            <h1>
              <span>{{ item.nickname }}</span>
              <i
                v-if="item.links"
                class="el-icon el-icon-monitor"
                v-tooltip="`点击后跳转到达人的官网`"
                @click="openUrl(item.links)"
              ></i>
              <i
                v-if="item.verified"
                :style="{ color: `#${item.verified}` }"
                class="icon-renzheng iconfont"
                v-tooltip="`已认证`"
              ></i>
              <i
                v-if="item.country_code"
                class="el-icon el-icon-location-information"
                v-tooltip="`达人所属地区：${item.country_code}`"
              ></i>
              <i
                v-if="item.page_url"
                class="el-icon el-icon-link"
                v-tooltip="`点击跳转到主页地址`"
                @click="openUrl(item.page_url)"
              ></i>
              <i
                v-if="item.emails"
                class="el-icon el-icon-message"
                @click="common._copy(item.emails)"
                v-tooltip="`主页关联的邮箱账号：${item.emails}，点击可复制`"
              ></i>
              <i
                v-if="item.addtime"
                class="el-icon el-icon-date"
                style="font-size: 22px"
                v-tooltip="`${item.addtime} 加入`"
              ></i>
            </h1>
            <p>@{{ item.uniqueId }}</p>
            <p v-if="item.about">
              简介：
              <el-tooltip
                class="item"
                effect="dark"
                :content="item.about"
                placement="top"
              >
                <span>{{ item.about }}</span>
              </el-tooltip>
            </p>
          </div>
          <div class="numbers" style="flex: 0 0 220px">
            <el-row :gutter="20">
              <!-- <el-col :span="8">
                <p v-if="item.views > 0">
                  <span v-tooltip="`${item.views}`">{{
                    item.views2 || 0
                  }}</span>
                </p>
                <p v-else><span>0</span></p>
                <p>浏览量</p>
              </el-col> -->
              <el-col :span="10">
                <p v-if="item.followers > 0">
                  <span v-tooltip="`${item.followers}`">{{
                    item.followers2 || 0
                  }}</span>
                </p>
                <p v-else><span>0</span></p>
                <p>粉丝数</p>
              </el-col>
              <el-col :span="10">
                <p v-if="item.publish_count > 0">
                  <span v-tooltip="`${item.publish_count}`">{{
                    item.publish_count2 || 0
                  }}</span>
                </p>
                <p v-else><span>0</span></p>
                <p>推文数</p>
              </el-col>
            </el-row>
          </div>
        </section>
        <el-divider></el-divider>
      </section>
    </section>
    <el-checkbox-group
      v-model="checkedArray"
      @change="handleCheckedChange"
      v-else
    >
      <section
        v-for="item in list"
        :key="item.name || item.nickname"
        style="margin-bottom: 20px"
      >
        <section class="flex_content">
          <el-checkbox :label="item.id" style="margin-right: 10px">{{
            ""
          }}</el-checkbox>
          <el-avatar
            :src="item.avatarThumbKey"
            style="width: 50px; height: 50px"
          >
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-avatar>
          <div class="info_content">
            <h1>
              <span>{{ item.name || item.nickname }}</span>
              <i
                v-if="item.verification_status == 1"
                class="icon-renzheng iconfont"
                v-tooltip="`已经在Tiktok进行过认证`"
              ></i>
              <country-flag
                v-if="item.country_code"
                :country="item.country_code"
                size="normal"
                v-tooltip="`达人所属国家：${item.country}`"
              />
              <!-- <i v-if="item.sex" class="icon-nan iconfont"></i>
            <i class="icon-nv iconfont"></i> -->
              <i
                v-if="item.page_url"
                class="el-icon el-icon-link"
                v-tooltip="`点击跳转到主页地址`"
                @click="openUrl(item.page_url)"
              ></i>
              <i
                v-if="item.emails"
                class="el-icon el-icon-message"
                @click="common._copy(item.emails)"
                v-tooltip="`主页关联的邮箱账号：${item.emails}，点击可复制`"
              ></i>
              <i
                v-if="item.bioLink"
                class="el-icon el-icon-monitor"
                v-tooltip="`点击后跳转到达人的官网`"
                @click="openUrl(item.bioLink)"
              ></i>
              <!-- <i
              class="iconfont icon-instagram"
              v-tooltip="`拥有相同的instagram账号`"
            ></i> -->
            </h1>
            <p>ID: {{ item.uniqueId }}</p>
            <p v-if="item.about">
              简介：
              <el-tooltip
                class="item"
                effect="dark"
                :content="item.about"
                placement="top"
              >
                <span>{{ item.about }}</span>
              </el-tooltip>
            </p>
          </div>
          <div class="numbers" style="margin-right: 40px; flex: 0 0 400px">
            <el-row :gutter="18">
              <el-col :span="8">
                <p v-if="item.followers > 0">
                  <span v-tooltip="`${item.followers}`">{{
                    item.followers2 || 0
                  }}</span>
                </p>
                <p v-else><span>0</span></p>
                <p>粉丝数</p>
              </el-col>
              <el-col :span="8">
                <p v-if="item.heartCount > 0">
                  <span v-tooltip="`${item.heartCount}`">{{
                    item.heartCount2 || 0
                  }}</span>
                </p>
                <p v-else><span>0</span></p>
                <p>点赞数</p>
              </el-col>
              <el-col :span="8">
                <p v-if="item.publish_count > 0">
                  <span v-tooltip="`${item.publish_count}`">{{
                    item.publish_count2 || 0
                  }}</span>
                </p>
                <p v-else><span>0</span></p>
                <p>作品数</p>
              </el-col>
            </el-row>
          </div>
          <div style="flex: 0 0 80px">
            <el-button
              type="text"
              title="添加备注"
              style="margin-right: 15px"
              @click="addRemark(item)"
              ><i class="el-icon-edit-outline icon_"></i
            ></el-button>
            <el-popover
              ref="popover"
              popper-class="setClassifyPopover"
              placement="left"
              v-model="item.visible"
              trigger="manual"
            >
              <el-checkbox-group v-model="selectedClassifies">
                <el-checkbox
                  v-for="(item, index) in allClassify"
                  :key="index"
                  :label="item.id"
                  >{{ item.name }}
                </el-checkbox>
              </el-checkbox-group>
              <div class="btns">
                <el-button @click="closeItemPopover(key)">取消</el-button>
                <el-button type="primary" @click="setClassifyHandle"
                  >确定</el-button
                >
              </div>
              <el-button
                type="text"
                slot="reference"
                @click="setClassify(item, key)"
                title="设置分类"
                ><i class="el-icon-folder-add icon_"></i
              ></el-button>
            </el-popover>
          </div>
        </section>
        <el-divider></el-divider>
      </section>
    </el-checkbox-group>
    <el-dialog :visible.sync="dialogVisible" width="500px" title="添加备注">
      <section>
        <el-form
          :model="addData"
          ref="form1"
          label-width="100px"
          @submit.native.prevent
          @keyup.enter.native="addRemarkHandle"
        >
          <el-form-item
            prop="remarks"
            label="备注信息："
            :rules="[{ required: true, message: '请输入要填写的备注' }]"
          >
            <el-input
              show-word-limit
              maxlength="200"
              :autosize="{ minRows: 4, maxRows: 8 }"
              type="textarea"
              v-model="addData.remarks"
              placeholder="请输入要填写的备注"
            ></el-input>
          </el-form-item>
        </el-form>
      </section>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addRemarkHandle">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import CountryFlag from "vue-country-flag";
export default {
  props: ["list", "platform", "checkAll", "allClassify"],
  components: {
    CountryFlag,
  },
  data() {
    return {
      checkedArray: [],
      dialogVisible: false,
      addData: {
        remarks: "",
      },
      selectedClassifies: [],
      uid: [],
    };
  },
  watch: {
    checkAll(n) {
      console.log(n);
      if (n) {
        this.checkedArray = this.list.map((item) => item.id);
      } else {
        this.checkedArray = [];
      }
    },
    checkedArray(n) {
      console.log(n);
      this.$emit("getUids", n);
    },
  },
  methods: {
    setClassify(row, key) {
      console.log(row, key);
      this.uid[0] = row.id;
      this.list.forEach((item, i) => {
        if (key === i) {
          this.$set(this.list[key], "visible", true);
        } else {
          this.$set(this.list[i], "visible", false);
        }
      });
      this.selectedClassifies = row.tc_id;
    },
    closeItemPopover(index) {
      this.$set(this.list[index], "visible", false);
      this.selectedClassifies = [];
    },
    addRemark(item) {
      this.addData.id = item.id;
      this.dialogVisible = true;
    },
    addRemarkHandle() {
      console.log(this.addData);
      this.$refs["form1"].validate((valid) => {
        if (valid) {
          this.axios(
            "POST",
            `${this.$baseUrl}v1/TiktokUser/setRemarks`,
            this.addData
          ).then((res) => {
            if (res.code == 1) {
              this.$message({
                message: res.msg,
                type: "success",
              });
              this.dialogVisible = false;
              this.addData = {
                id: "",
                remarks: "",
              };
            }
          });
        } else {
          return false;
        }
      });
    },
    setClassifyHandle() {
      if (this.selectedClassifies.length < 1) {
        this.$message({
          message: "请选择分类",
          type: "warning",
        });
        return;
      }
      this.axios("POST", `${this.$baseUrl}v1/TiktokUser/writeUserClassify`, {
        cid: this.selectedClassifies,
        uid: this.uid,
      }).then((res) => {
        if (res.code == 1) {
          this.$emit("init", null);
          this.uid = [];
          this.selectedClassifies = [];
        }
      });
    },
    handleCheckedChange() {
      // this.$emit("getUids", v);
    },
    openUrl(url) {
      window.open(url);
    },
  },
};
</script>
<style lang="scss" scoped>
.flex_content {
  display: flex;
  align-items: center;
  .el-avatar {
    width: 100px;
    height: 100px;
    margin-right: 20px;
  }
  .info_content {
    flex: 1;
    padding-right: 20px;
    h1 {
      font-size: 14px;
      display: flex;
      align-items: center;
      height: 20px;
      span {
        font-family: "Helvetica Neue";
        font-weight: bold;
      }
      .normal-flag {
        margin: 0 -0.9em 0em -0.7em;
        margin-left: -4px;
      }
      .iconfont,
      .el-icon {
        font-size: 24px;
        font-weight: bold;
        margin-left: 10px;
      }
      .icon-renzheng {
        color: #df8b0e;
      }
      .el-icon-connection {
        color: #c51f1f;
      }
      .el-icon-link,
      .el-icon-monitor {
        color: #409eff;
        cursor: pointer;
      }
      .el-icon-message {
        color: #52d90f;
        cursor: pointer;
      }
      .el-icon-location-information {
        color: #56983b;
      }
      .icon-instagram {
        color: #d4237a;
      }
    }
    p {
      color: #909399;
      line-height: 18px;
      margin-top: 5px;
      font-size: 12px;
      font-family: "Microsoft YaHei";
      word-break: break-all;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
    }
  }
  .numbers {
    flex: 0 0 300px;
    .el-row {
      p {
        font-size: 14px;
        &:nth-child(1) {
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>
