<template>
  <div class="quotaRange">
    <el-form ref="form" :inline="true" :model="form" :rules="rules">
      <el-form-item prop="min">
        <el-input
          type="number"
          v-model.number="form.min"
          placeholder="最小值"
          @change="handleMinChange"
        />
      </el-form-item>
      -
      <el-form-item prop="max">
        <el-input
          v-model.number="form.max"
          type="number"
          placeholder="最大值"
          @change="handleMaxChange"
        />
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
const MIN_NUMBER = 1;
// const MAX_NUMBER = 100000;
export default {
  data() {
    return {
      form: { min: "", max: "" },
      rules: {
        min: [
          { validator: this.validateCom, trigger: "blur" },
          { validator: this.validateMin, trigger: "blur" },
        ],
        max: [
          { validator: this.validateCom, trigger: "blur" },
          { validator: this.validateMax, trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    getFormData() {
      const ret = {};
      this.$refs.form.validate((valid) => {
        ret.valid = valid;
        ret.form = this.form;
      });
      return ret;
    },
    resetForm() {
      this.$refs.form.resetFields();
    },
    handleMinChange() {
      this.$refs.form.validateField("max");
      this.$emit("change", this.form);
    },
    handleMaxChange() {
      this.$refs.form.validateField("min");
      this.$emit("change", this.form);
    },
    validateCom(rule, value, callback) {
      const one = Number(value);
      if (Number.isInteger(one)) {
        if (one < MIN_NUMBER) {
          return callback(new Error("输入值必须大于0"));
        }
        return callback();
      }
      return callback(new Error("输入值必须为正整数"));
    },
    validateMin(rule, value, callback) {
      const one = Number(value);
      const max = Number(this.form.max);
      if (!max || one < max) {
        return callback();
      }
      return callback(new Error("输入值不得大于最大值"));
    },
    validateMax(rule, value, callback) {
      const one = Number(value);
      const min = Number(this.form.min);
      if (!min || one > min) {
        return callback();
      }
      return callback(new Error("输入值不得小于最小值"));
    },
  },
};
</script>
<style lang="scss" scoped>
.quotaRange {
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  :deep(.el-form) {
    .el-form-item {
      margin-right: 0;
      .el-input {
        width: 100px;
        .el-input__inner {
          text-align: center;
          border: none;
        }
      }
    }
  }
}
</style>
