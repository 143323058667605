<template>
  <div class="searchEngines">
    <el-card v-loading="loading">
      <el-input
        class="searchInput"
        placeholder="请输入主页名称、用户名进行搜索"
        v-model="input1"
        clearable
        @change="inputChange"
      >
        <el-button slot="append" icon="el-icon-search"></el-button>
      </el-input>
      <section v-show="closedShowMore">
        <el-form label-width="120px">
          <el-form-item label="国家 / 地区：" v-if="countryConfig.status">
            <div style="flex: 1; display: flex; align-items: flex-start">
              <el-checkbox-button
                class="positionRelative"
                @change="checkboxGroup1 = []"
                :value="checkboxGroup1.length === 0"
                >全部</el-checkbox-button
              >
              <el-checkbox-group
                v-model="checkboxGroup1"
                :class="{ flod: flod1 }"
              >
                <el-checkbox-button style="width: 80px"></el-checkbox-button>
                <el-checkbox-button
                  style="margin-bottom: 10px"
                  v-for="city in countryConfig.content"
                  :label="city.code"
                  :key="city.code"
                  >{{ city.country_name }}</el-checkbox-button
                >
                <el-select
                  filterable
                  class="custom_select"
                  v-if="!flod1"
                  v-model="checkboxGroup1"
                  multiple
                  collapse-tags
                  placeholder="请选择国家和地区"
                >
                  <el-option
                    v-for="city in allCountrys"
                    :label="city.country_name"
                    :key="city.code"
                    :value="city.code"
                  >
                  </el-option>
                </el-select>
              </el-checkbox-group>
            </div>
            <el-button v-if="flod1" type="text" @click="flod1 = false"
              >更多分类 <i class="el-icon-arrow-down el-icon--right"></i
            ></el-button>
            <el-button v-else type="text" @click="flod1 = true"
              >收起更多 <i class="el-icon-arrow-up el-icon--right"></i
            ></el-button>
          </el-form-item>
          <el-form-item label="类别：" v-if="cateConfig.status">
            <div style="flex: 1; display: flex; align-items: flex-start">
              <el-checkbox-button
                class="positionRelative"
                @change="checkboxGroup2 = []"
                :value="checkboxGroup2.length === 0"
                >全部</el-checkbox-button
              >
              <el-checkbox-group
                v-model="checkboxGroup2"
                :class="{ flod: flod2 }"
              >
                <el-checkbox-button style="width: 80px"></el-checkbox-button>
                <el-checkbox-button
                  style="margin-bottom: 10px"
                  v-for="city in cateConfig.content"
                  :label="city"
                  :key="city"
                  >{{ city }}</el-checkbox-button
                >
                <el-select
                  class="custom_select"
                  v-if="!flod2"
                  v-model="checkboxGroup2"
                  multiple
                  filterable
                  collapse-tags
                  placeholder="请选择类别"
                >
                  <el-option
                    v-for="city in allCategories"
                    :label="city"
                    :key="city"
                    :value="city"
                  >
                  </el-option>
                </el-select>
              </el-checkbox-group>
            </div>
            <el-button v-if="flod2" type="text" @click="flod2 = false"
              >更多分类 <i class="el-icon-arrow-down el-icon--right"></i
            ></el-button>
            <el-button v-else type="text" @click="flod2 = true"
              >收起更多 <i class="el-icon-arrow-up el-icon--right"></i
            ></el-button>
          </el-form-item>
          <el-form-item label="粉丝数：" v-if="fans.status">
            <el-checkbox-button
              class="positionRelative"
              @change="checkboxGroup3 = []"
              :value="checkboxGroup3.length === 0"
              >全部</el-checkbox-button
            >
            <el-checkbox-group
              style="flex: 1"
              v-model="checkboxGroup3"
              class="noMargin"
            >
              <el-checkbox-button style="width: 80px"></el-checkbox-button>
              <el-checkbox-button
                v-for="(item, key) in fans.content"
                :label="key"
                :key="key"
                >{{ item.name }}</el-checkbox-button
              >
            </el-checkbox-group>
            <quotaRange @change="quotoChange"></quotaRange>
          </el-form-item>
          <el-form-item
            label="其他条件："
            v-if="
              accountConfig.status ||
              contactConfig.status ||
              websiteConfig.status ||
              commerceConfig.status
            "
          >
            <section style="width: 100%">
              <el-select
                placeholder="联系方式"
                v-if="contactConfig.status"
                v-model="contact"
                style="margin-right: 20px; width: 150px"
                clearable
              >
                <el-option label="邮箱" value="1"></el-option>
                <el-option label="无联系方式" value="2"></el-option>
              </el-select>
              <el-select
                placeholder="官网信息"
                v-if="websiteConfig.status"
                v-model="website"
                style="margin-right: 20px; width: 150px"
                clearable
              >
                <el-option label="有官网" value="1"></el-option>
                <el-option label="无官网" value="2"></el-option>
              </el-select>
              <el-select
                placeholder="第三方账户"
                v-if="accountConfig.status"
                v-model="account"
                style="margin-right: 20px; width: 150px"
                clearable
              >
                <el-option label="有第三方账户" value="1"></el-option>
                <el-option label="无第三方账户" value="2"></el-option>
              </el-select>
              <el-select
                placeholder="带货情况"
                v-if="commerceConfig.status"
                v-model="commerce"
                style="margin-right: 20px; width: 150px"
                clearable
              >
                <el-option label="带货" value="1"></el-option>
                <el-option label="不带货" value="2"></el-option>
              </el-select>
            </section>
          </el-form-item>
        </el-form>
        <el-divider></el-divider>
        <el-form
          label-width="120px"
          v-show="
            checkboxGroup1.length > 0 ||
            checkboxGroup2.length > 0 ||
            checkboxGroup3.length > 0 ||
            contact == 1 ||
            contact == 2 ||
            website == 1 ||
            website == 2 ||
            account == 1 ||
            account == 2 ||
            commerce == 1 ||
            commerce == 2
          "
        >
          <el-form-item style="margin-bottom: 0" label="已选条件：">
            <div style="flex: 1">
              <el-tag
                effect="dark"
                style="margin-right: 10px"
                v-for="(tag, index) in checkboxGroup1"
                :key="tag"
                closable
                @close="countryTagClose(index)"
              >
                国家或地区：{{ getCountryName(tag) }}
              </el-tag>
              <el-tag
                effect="dark"
                style="margin-right: 10px"
                v-for="(tag, index) in checkboxGroup2"
                :key="tag"
                closable
                @close="cateTagClose(index)"
              >
                类别：{{ tag }}
              </el-tag>
              <el-tag
                effect="dark"
                style="margin-right: 10px"
                v-for="(tag, index) in checkboxGroup3"
                :key="tag"
                @close="fanTagClose(index)"
                closable
              >
                粉丝数：{{ getFansStr(tag) }}
              </el-tag>
              <el-tag
                v-if="contact == 1 || contact == 2"
                effect="dark"
                style="margin-right: 10px"
                @close="contact = ''"
                closable
              >
                联系方式：{{ contact == 1 ? "邮箱" : "无" }}
              </el-tag>
              <el-tag
                v-if="website == 1 || website == 2"
                effect="dark"
                style="margin-right: 10px"
                @close="website = ''"
                closable
              >
                官网信息：{{ website == 1 ? "有" : "无" }}
              </el-tag>
              <el-tag
                v-if="account == 1 || account == 2"
                effect="dark"
                style="margin-right: 10px"
                @close="account = ''"
                closable
              >
                第三方账户：{{ account == 1 ? "有" : "无" }}
              </el-tag>
              <el-tag
                v-if="commerce == 1 || commerce == 2"
                effect="dark"
                style="margin-right: 10px"
                @close="commerce = ''"
                closable
              >
                带货情况：{{ commerce == 1 ? "有" : "无" }}
              </el-tag>
            </div>
            <el-button @click="clearSearch">清除条件</el-button>
          </el-form-item>
        </el-form>
      </section>
    </el-card>
    <el-button
      :icon="closedShowMore ? 'el-icon-d-arrow-left' : 'el-icon-d-arrow-right'"
      class="flodUpBtn"
      @click="flodUpChartSection"
    ></el-button>
  </div>
</template>
<script>
import quotaRange from "../childComponents/quotaRange.vue";
export default {
  props: ["platform"],
  components: {
    quotaRange,
  },
  data() {
    return {
      closedShowMore: true,
      loading: false,
      input1: "",
      checkboxGroup1: [],
      checkboxGroup2: [],
      checkboxGroup3: [],
      account: "",
      contact: "",
      website: "",
      commerce: "",
      flod1: true,
      flod2: true,
      fans: {
        status: 0,
        content: [],
      },
      cities: [],
      categories: [],
      allCategories: [],
      allCountrys: [],
      inputFollowers: [],
      countryConfig: {
        status: 0,
        content: [],
      },
      cateConfig: {
        status: 0,
        content: [],
      },
      accountConfig: { status: 0 },
      contactConfig: { status: 0 },
      websiteConfig: { status: 0 },
      commerceConfig: { status: 0 },
    };
  },
  computed: {
    listenChange() {
      const {
        checkboxGroup1,
        checkboxGroup2,
        checkboxGroup3,
        contact,
        account,
        website,
        commerce,
      } = this;
      let checkboxGroup3_ = this.$lodash.cloneDeep(checkboxGroup3).sort();
      return {
        checkboxGroup1,
        checkboxGroup2,
        checkboxGroup3_,
        contact,
        account,
        website,
        commerce,
      };
    },
  },
  watch: {
    listenChange(val) {
      this.search(val);
    },
  },
  created() {
    this.getCountrys();
    this.getFollowers();
    this.getCategories();
    this.getSetting();
  },
  methods: {
    flodUpChartSection() {
      this.closedShowMore = this.closedShowMore === false ? true : false;
    },
    getCountrys() {
      this.common.getCountrys().then((res) => {
        if (res.length > 0) {
          this.allCountrys = res;
        }
      });
    },
    getFollowers() {
      this.common.getFollowers().then((res) => {
        if (res.length > 0) {
          this.allFollowers = res;
        }
      });
    },
    getCategories() {
      this.axios(
        "get",
        `${this.$baseUrl}v1${
          this.platform === 1
            ? "/categoryFb/topLists"
            : "/TiktokUser/getClassifyList"
        }`,
        {
          page: 1,
          page_num: 1000,
        }
      ).then((res) => {
        if (res.code == 1) {
          this.allCategories = res.data.data.map((item) => {
            if (this.platform == 1) {
              return item.category;
            } else {
              return item.name;
            }
          });
        }
      });
    },
    getSetting() {
      let type = "";
      if (this.platform == 1) {
        type = "facebook";
      } else if (this.platform == 2) {
        type = "tiktok";
      } else if (this.platform == 3) {
        type = "youtube";
      } else if (this.platform == 4) {
        type = "twitter";
      }
      this.loading = true;
      this.axios("get", `${this.$baseUrl}v1/TiktokSearch/lists`, {
        type: type,
      }).then((res) => {
        this.loading = false;
        if (res.code == 1) {
          res.data.forEach((item) => {
            if (item.keyword === "country") {
              this.countryConfig = item;
            }
            if (item.keyword === "category") {
              this.cateConfig = item;
            }
            if (item.keyword === "followers") {
              this.fans = item;
            }
            if (item.keyword === "contact") {
              this.contactConfig = item;
            }
            if (item.keyword === "website") {
              this.websiteConfig = item;
            }
            if (item.keyword === "account") {
              this.accountConfig = item;
            }
            if (item.keyword === "commerce") {
              this.commerceConfig = item;
            }
          });
        }
      });
    },
    countryTagClose(index) {
      this.checkboxGroup1.splice(index, 1);
    },
    cateTagClose(index) {
      this.checkboxGroup2.splice(index, 1);
    },
    fanTagClose(index) {
      this.checkboxGroup3.splice(index, 1);
    },
    inputChange() {
      this.$emit("nameChange", this.input1);
    },
    quotoChange(val) {
      console.log(val);
      this.inputFollowers = [
        {
          start: val.min,
          end: val.max || "",
        },
      ];
      let obj = {
        category: this.checkboxGroup2,
        country: this.checkboxGroup1,
        followers: [val.min, val.max || ""],
        name: this.input1,
        contact: this.contact,
        website: this.website,
        account: this.account,
        commerce: this.commerce,
      };
      this.$emit("change", obj);
    },
    search(val) {
      let followersArr = val.checkboxGroup3_;
      console.log(followersArr);
      let a = [];
      if (followersArr.length > 1) {
        a = [
          this.fans.content[followersArr[0]].start,
          this.fans.content[followersArr[followersArr.length - 1]].end || "",
        ];
      } else if (followersArr.length == 1) {
        a = [
          this.fans.content[followersArr[0]].start,
          this.fans.content[followersArr[0]].end,
        ];
      } else if (followersArr.length === 0 && this.inputFollowers.length > 0) {
        a = [this.inputFollowers[0].start, this.inputFollowers[0].end || ""];
      }
      let obj = {
        category: val.checkboxGroup2,
        country: val.checkboxGroup1,
        followers: a,
        name: val.input1,
        contact: val.contact,
        website: val.website,
        account: val.account,
        commerce: val.commerce,
      };
      console.log(obj);
      this.$emit("change", obj);
    },
    getFansStr(index) {
      console.log(index);
      return this.fans.content[index].name;
      // return (
      //   this.fans[index].start +
      //   (this.fans[index].end ? `万-` + this.fans[index].end + `万` : `万+`)
      // );
    },
    getCountryName(code) {
      let name = "";
      if (code == "") {
        name = "全部";
      } else {
        this.allCountrys.forEach((item) => {
          if (item.code == code) {
            name = item.country_name;
          }
        });
      }
      return name;
    },
    clearSearch() {
      this.checkboxGroup1 = [];
      this.checkboxGroup2 = [];
      this.checkboxGroup3 = [];
      this.account = "";
      this.contact = "";
      this.website = "";
      this.commerce = "";
    },
  },
};
</script>
<style lang="scss" scoped>
.searchEngines {
  margin-bottom: 20px;
  position: relative;
  .flodUpBtn {
    transform: rotate(90deg);
    width: 30px;
    padding: 0;
    height: 100px;
    position: absolute;
    z-index: 10;
    bottom: -64px;
    left: 50%;
    border-left: none;
  }
  :deep(.searchInput) {
    width: 600px;
    margin-bottom: 20px;
    .el-input__inner {
      height: 46px;
      line-height: 46px;
      font-size: 16px;
    }
  }
  :deep(.el-form-item__content) {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .el-checkbox-button__inner {
      border: 0;
      border-radius: 4px;
    }
    .el-checkbox-group {
      position: relative;
      left: -80px;
      &.flod {
        height: 36px;
        overflow: hidden;
      }
      .custom_select {
        position: relative;
        top: 5px;
        margin-right: 20px;
        width: 300px;
      }
      .el-checkbox-button {
        margin-right: 10px;
        .el-checkbox-button__inner {
          border: 0;
          border-radius: 4px;
        }
      }
    }

    .positionRelative {
      position: relative;
      z-index: 1;
    }
    // .noMargin {
    //   .el-checkbox-button {
    //     margin-right: 0px;
    //   }
    // }
  }
}
</style>
